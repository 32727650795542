import React from 'react'
import './Section2.css'

const Section2 = () => {
  return (
    <div className="section2-container">
      <h1 data-aos="fade-down" className="section2-heading">~ Welcome to 6LYETS ~</h1>
      <p data-aos="fade-up"  className="section2-introduction">At 6Lytes, we believe in the extraordinary power of community to drive personal transformation. We are dedicated to elevating each individual by providing premium support and clean, effective products. With a focus on zero-junk ingredients, we empower you to embrace your journey and overcome daily challenges.</p>
    </div>
  )
}

export default Section2
