import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import ProductCard from '../../components/ProductCard';
import { useNavigate } from 'react-router-dom';
import apple from '../../assets/6LYETS_RENDER_APPLE.png';
import lime from '../../assets/6LYETS_RENDER_LIME.png';
import orange from '../../assets/6LYETS_RENDER_ORANGE.png';
import watermelon from '../../assets/6LYETS_RENDER_WATERMELON.png';
import './Shop.css';
import Aos from 'aos';
import AxiosClient from 'utilities/axiosClient';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const Shop = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    try {
      const userTimezone = dayjs.tz.guess();
      const response = await AxiosClient.get('/products', {
        params: {
          timezone: userTimezone,
        },
      });

      if (response.data && response?.data?.length > 0) {
        setProducts(response.data);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }, []);

  useEffect(() => {
    Aos.init({ duration: 600, delay: 200 });
    fetchProducts();
  }, [fetchProducts]);

  const viewProductDetail = (id) => {
    navigate(`/collections/products/${id}`);
  };

  return (
    <div className='shop-container'>
      <h2 className='shop-title' data-aos='fade-up' data-aos-once='true'>
        Our Products
      </h2>

      {products?.length > 0 ? (
        <div
          className='product-grid-shop'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='200'
          data-aos-once='true'
        >
          {products?.map((product, index) => (
            <div
              key={product.id}
              onClick={() => viewProductDetail(product.id)}
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-delay={`${index * 100}`}
              data-aos-once='true'
            >
              <ProductCard
                imageUrl={
                  product.productImages?.[0]?.imageUrl || product.imageUrl
                }
                name={product.name}
                price={product.price}
              />
            </div>
          ))}
        </div>
      ) : (
        <p className='no-products'>No products available</p>
      )}
    </div>
  );
};

export default Shop;
