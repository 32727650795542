import React from 'react';
import styles from './TodoList.module.css';
import { AiOutlinePlus, AiOutlineEllipsis } from 'react-icons/ai';

const TodoList = () => {
  return (
    <div className={styles.todoList}>
      <h3 className={styles.title}>Todos</h3>
      <ul>
        <li className={styles.completed}>
          <p>Finish the report</p>
          <AiOutlineEllipsis className={styles.icon} />
        </li>
        <li className={styles.notCompleted}>
          <p>Update website</p>
          <AiOutlineEllipsis className={styles.icon} />
        </li>
        <li className={styles.completed}>
          <p>Call the client</p>
          <AiOutlineEllipsis className={styles.icon} />
        </li>
      </ul>
      <button className={styles.addTodo}>
        <AiOutlinePlus /> Add New
      </button>
    </div>
  );
};

export default TodoList;
