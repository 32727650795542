import React, { createContext, useContext, useState, useCallback } from 'react';
import axios from 'axios';
import AxiosClient from 'utilities/axiosClient';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [currency, setCurrency] = useState('')
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Toggle cart visibility and fetch cart items on open
  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
    if (!isCartOpen) fetchCart();
  };
        const userTimezone = dayjs.tz.guess();

  // Fetch Cart from Backend
  const fetchCart = useCallback(async () => {
    try {
      const response = await AxiosClient.get('/cart', {
        params: {
          timezone: userTimezone,
        },
      });
      console.log(response.data.result)
      setCart(response.data.result.cartItems);
      setCurrency(response.data.currency)
    } catch (error) {
      console.error('Error fetching cart:', error);
    }
  }, []);

  // Add or Update Product in Cart
  const updateOrCreateCart = async (productId, quantity) => {
    try {
      await AxiosClient.post(
        '/cart/update-or-create',
        { productId, quantity },
        { withCredentials: true },
      );
      fetchCart(); // Re-fetch cart after update
    } catch (error) {
      console.error('Error updating cart:', error);
    }
  };

  // Remove item from cart
  const removeItem = async (productId) => {
    try {
      await AxiosClient.post(
        '/cart/remove',
        { productId },
        { withCredentials: true },
      );
      fetchCart(); // Re-fetch cart after removal
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        fetchCart,
        isCartOpen,
        toggleCart,
        updateOrCreateCart,
        removeItem,
        currency,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
