import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apple from "../../../assets/6LYETS_RENDER_APPLE.png";
import lime from "../../../assets/6LYETS_RENDER_LIME.png";
import orange from "../../../assets/6LYETS_RENDER_ORANGE.png";
import watermelon from "../../../assets/6LYETS_RENDER_WATERMELON.png";
import "./Section1.css";

const ImageList = [
  {
    id: 1,
    img: apple,
    title: "Apple",
    description: "",
  },
  {
    id: 2,
    img: lime,
    title: "Lime",
    description: "",
  },
  {
    id: 3,
    img: orange,
    title: "Orange",
    description: "",
  },
  {
    id: 4,
    img: watermelon,
    title: "Watermelon",
    description: "",
  },
];

const Section1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % ImageList.length);
    }, 5000); // Change the image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    navigate("/shop");
  };

  return (
    <div className="hero-container">
      <div className="hero-content">
        <div className="grid-container">
          <div className="text-content">
            <h1 className="hero-title" key={currentIndex}>
              Checkout Our Latest
              <br />
               Product -
              <span
              className="change-text"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                {ImageList[currentIndex].title}
              </span>
            </h1>
            <p
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="100"
              className="hero-description"
            >
              {ImageList[currentIndex].description}
            </p>
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
              className="hero-button"
            >
              <button onClick={handleClick} className="order-btn">
                Shop Now
              </button>
            </div>
          </div>
          <div className="image-section-hero">
            <img
              key={currentIndex}
              src={ImageList[currentIndex].img}
              alt={ImageList[currentIndex].title}
              className="hero-image"
              data-aos="fade-in"
              data-aos-duration="1000"
              data-aos-delay="1000"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
