import React from "react";
import "./Section8.css";
import Apple from "../../../assets/6LYETS_RENDER_APPLE.png";
import Watermelon from "../../../assets/6LYETS_RENDER_WATERMELON.png";

const Section8 = () => {
  const sections = [
    {
      title: "6LYETS - Apple",
      description: "Our premium electrolyte hydration powder designed for everyday use. Packed with all six essential electrolytes, four vital vitamins, and zinc, Hydrate replenishes what your body loses throughout the day.",
      delay: 700,
    },
    {
      title: "6LYETS - Watermelon",
      description: "Our premium electrolyte hydration powder designed for everyday use. Packed with all six essential electrolytes, four vital vitamins, and zinc, Hydrate replenishes what your body loses throughout the day.",
      delay: 700,
    },
  ];

  return (
    <section style={{ background: "linear-gradient(to right, #f5a7e6, #ced5fc)"}}>
      {sections.map((section, index) => (
        <div className="section8-container" key={index}>
          {index % 2 === 0 ? (
            <> 
            <div className="even">
              <div
                className="section8-image-container"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src={Apple} alt="" className="section8-image" />
              </div>
              <div className="section8-content">
                <div className="section8-text space-y-4 lg:max-w-[450px]">
                  <h1
                    data-aos="fade-up"
                    data-aos-delay={section.delay}
                    className="section8-title"
                  >
                    {section.title}
                  </h1>
                  <p data-aos="fade-up" data-aos-delay={section.delay + 200}>
                    {section.description}
                  </p>
                  <button
                    data-aos="fade-up"
                    data-aos-delay={section.delay + 600}
                    className="section8-button"
                  >
                    Shop Now
                  </button>
                </div>
              </div>
              </div>
            </>
          ) : (
            <>
            <div className="old">
              <div className="section8-content">
                <div className="section8-text space-y-4 lg:max-w-[450px]">
                  <h1
                    data-aos="fade-up"
                    data-aos-delay={section.delay}
                    className="section8-title"
                  >
                    {section.title}
                  </h1>
                  <p data-aos="fade-up" data-aos-delay={section.delay + 200}>
                    {section.description}
                  </p>
                  <button
                    data-aos="fade-up"
                    data-aos-delay={section.delay + 600}
                    className="section8-button"
                  >
                    Shop Now
                  </button>
                </div>
              </div>
              <div
                className="section8-image-container"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src={Watermelon} alt="" className="section8-image" />
              </div>
              </div>
            </>
          )}
        </div>
      ))}
    </section>
  );
};

export default Section8;
