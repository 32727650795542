import React, { useState, useEffect, useRef } from "react";
import Hydrate from "../../assets/hydrate.webp";
import banner from "../../assets/banner1.png";
import { IoIosCloseCircle } from "react-icons/io";
import { CiShoppingCart } from "react-icons/ci";
import CartSlider from "components/CartSlider";

const ProductCard = ({ product, addToBox, updateQuantity, boxItems }) => {
  const existingItem = boxItems.find((item) => item.id === product.id);
  const [quantity, setQuantity] = useState(
    existingItem ? existingItem.quantity : 0
  );

  const handleAddToBox = () => {
    if (quantity === 0) {
      addToBox(product);
      setQuantity(1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
    updateQuantity(product, quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      updateQuantity(product, quantity - 1);
    } else {
      setQuantity(0);
      updateQuantity(product, 0);
    }
  };

  return (
    <div className="border rounded-md text-center transition-transform duration-300 hover:shadow-lg hover:scale-[1.01] w-[22rem]">
      <div className="w-60 h-64 mt-2 mx-auto">
        <img src={product.image} alt={product.name} className="w-full h-full" />
      </div>
      <h3 className="text-lg  my-3">{product.name}</h3>
      <p className="text-black text-lg font-extrabold">Dhs. {product.price}</p>
      {quantity === 0 ? (
        <button
          onClick={handleAddToBox}
          className="mt-1 mb-3 bg-black text-white py-3 w-[95%] text-md font-semibold rounded-md"
        >
          Add to box
        </button>
      ) : (
        <div className="mx-2  flex justify-center items-center rounded-lg">
          <button
            onClick={decreaseQuantity}
            className="mt-1 mb-3 bg-black text-white py-3 w-[30%] text-sm rounded-md"
          >
            -
          </button>
          <span className="text-xl font-semibold  px-14  border-gray-300 rounded-md">
            {quantity}
          </span>
          <button
            onClick={increaseQuantity}
            className="mt-1 mb-3 bg-black text-white py-3 w-[30%] text-sm rounded-md"
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

const Bundle = () => {
  const [boxItems, setBoxItems] = useState([]);
  const [isFixed, setIsFixed] = useState(false);
  const bottomRef = useRef(null); // Reference to the element below product cards

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If the section is not visible (i.e., we have scrolled past it), set isFixed to true
        setIsFixed(!entry.isIntersecting);
      },
      {
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    // Observe the element after product cards
    if (bottomRef.current) {
      observer.observe(bottomRef.current);
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current);
      }
    };
  }, []);

  const products = [
    { id: 1, name: "Hydrate Discovery Pack", price: 10, image: Hydrate },
    { id: 2, name: "Product 2", price: 20, image: Hydrate },
    { id: 3, name: "Product 3", price: 30, image: Hydrate },
    { id: 4, name: "Product 4", price: 40, image: Hydrate },
    { id: 5, name: "Product 5", price: 50, image: Hydrate },
  ];

  const addToBox = (product) => {
    if (!boxItems.some((item) => item.id === product.id)) {
      setBoxItems([...boxItems, { ...product, quantity: 1 }]);
    }
  };

  const updateQuantity = (product, quantity) => {
    if (quantity === 0) {
      setBoxItems(boxItems.filter((item) => item.id !== product.id));
    } else {
      setBoxItems(
        boxItems.map((item) =>
          item.id === product.id ? { ...item, quantity } : item
        )
      );
    }
  };

  const total = boxItems.reduce(
    (sum, item) => sum + item.price * item.quantity,
    0
  );

  return (
    <div className="bg-white p-8 px-36">
      {/* Wrapper for the Banner and Products */}
      <div className="w-[90%] mx-auto">
        {/* Image Banner */}
        <div className="flex justify-center mb-8">
          <div className="w-full h-[350px]">
            <img
              src={banner}
              alt="Banner"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        {/* Product Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-8">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              product={product}
              addToBox={addToBox}
              updateQuantity={updateQuantity}
              boxItems={boxItems}
            />
          ))}
        </div>

        {/* Target element for IntersectionObserver */}
        <div ref={bottomRef} className="h-2"></div>
      </div>

      {/* Sticky section that appears at the bottom */}
      <div
        className={`transition-all ${
          isFixed
            ? "fixed bottom-0 left-0 w-full bg-white shadow-lg px-[4rem]"
            : ""
        }`}
      >
        <div className="relative text-center px-[4rem]">
          {/* Thin gray line */}
          <div className="relative w-full h-[1px] bg-gray-300 mb-2">
            {/* Cart Icon and Number in the center */}
            <div className="absolute left-[50%] top-[-18px] transform -translate-x-[50%] flex items-center space-x-2 bg-black text-white px-2 py-1 rounded-t-xl rounded-b-xl ">
              <span className="text-sm font-bold">{boxItems.length}</span>
              <CiShoppingCart size={"20"} className="text-white text-lg" />
            </div>
          </div>

          {/* Centered text */}
          <p className="font-semibold my-4">
            Add 3 more product(s) to get 10% OFF!
          </p>
        </div>

        {/* Green Line Progress */}
        <div className="mb-8 px-44">
          <div className="w-full h-2 bg-green-100 rounded-full">
            <div
              className="h-2 bg-green-500 rounded-full"
              style={{ width: `${(boxItems.length / products.length) * 100}%` }}
            ></div>
          </div>
        </div>

        {/* Displaying added products */}
        <div className="mb-8 px-44">
          <div className="flex flex-wrap gap-4">
            {boxItems.map((item) => (
              <div
                key={item.id}
                className="relative p-3 flex items-center space-x-3 w-48"
              >
                {/* Remove product button */}
                <div className="relative border-2 border-gray-300 p-2">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="w-12 h-12 object-cover"
                  />
                  <button
                    onClick={() => updateQuantity(item, 0)}
                    className="absolute top-0 left-0 text-black text-md"
                  >
                    <IoIosCloseCircle />
                  </button>
                </div>

                {/* Product Details */}
                <div className="flex flex-col">
                  <span className="text-sm font-semibold">{item.name}</span>
                  <span className="text-xs text-gray-600">
                    Dhs. {item.price} x {item.quantity}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Back, Total, and Next Buttons */}
        <div className="flex justify-between items-center px-44">
          <button className="bg-gray-300 text-black py-3 px-20 rounded-md">
            Back
          </button>
          <span className="font-semibold">Total: Dhs. {total}</span>
          <button className="bg-black text-white py-3 px-20 rounded-md">
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Bundle;
