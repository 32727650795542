import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styles from './DummyCheckout.module.css'; // Import the module
import { useCart } from 'contexts/CartContext'; // Import Cart Context
import AxiosClient from 'utilities/axiosClient';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutDemo = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [deliveryInfo, setDeliveryInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    emirate: '',
    phone: '',
  });
  const { cart, fetchCart } = useCart(); // Use cart context
  const stripe = useStripe();
  const elements = useElements();

  // Fetch cart items on component mount
  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  // Calculate total amount
  const totalAmount = cart.reduce(
    (total, item) => total + item.product.price * item.quantity,
    0,
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      setIsProcessing(false);
      return;
    }

    try {
      // Create Payment Intent with the total amount
      const response = await AxiosClient.post(
        '/payment/create-payment-intent',
        {
          amount: totalAmount,
        },
      );

      const clientSecret = response.data.clientSecret;

      const cardElement = elements.getElement(CardElement);
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: `${deliveryInfo.firstName} ${deliveryInfo.lastName}`,
            email: 'user@example.com', // Replace with the user's actual email
          },
        },
      });

      if (result.error) {
        console.error(result.error.message);
        setIsProcessing(false);
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          console.log('Payment succeeded!');
          // Save order details to the backend
          await AxiosClient.post('/payment/process-order', {
            deliveryInfo,
            cartItems: cart,
            paymentIntentId: result.paymentIntent.id,
          });
          // Clear cart, redirect to success page, etc.
          setIsProcessing(false);
        }
      }
    } catch (error) {
      console.error('Error during payment process:', error);
      setIsProcessing(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  return (
    <div className={styles.checkoutDemo}>
      <h1>Checkout Page</h1>
      <div className={styles.checkoutContainer}>
        {/* Product Summary */}
        <div className={styles.productSummary}>
          <h2>Your Order</h2>
          {cart.map((item) => (
            <div key={item.id} className={styles.productItem}>
              <p>Product Name: {item.product.name}</p>
              <p>Price: AED {item.product.price.toFixed(2)}</p>
              <p>Quantity: {item.quantity}</p>
              <p>
                Subtotal: AED {(item.product.price * item.quantity).toFixed(2)}
              </p>
            </div>
          ))}
          <div className={styles.orderTotal}>
            <p>Total: AED {totalAmount.toFixed(2)}</p>
          </div>
        </div>

        {/* Delivery Info Form */}
        <div className={styles.deliveryInfo}>
          <h2>Delivery Information</h2>
          <form>
            <div className={styles.inputGroup}>
              <input
                type='text'
                name='firstName'
                placeholder='First Name'
                value={deliveryInfo.firstName}
                onChange={handleInputChange}
                required
              />
              <input
                type='text'
                name='lastName'
                placeholder='Last Name'
                value={deliveryInfo.lastName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type='text'
                name='address'
                placeholder='Full Address'
                value={deliveryInfo.address}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type='text'
                name='city'
                placeholder='City'
                value={deliveryInfo.city}
                onChange={handleInputChange}
                required
              />
              <input
                type='text'
                name='emirate'
                placeholder='Emirate'
                value={deliveryInfo.emirate}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={styles.inputGroup}>
              <input
                type='text'
                name='phone'
                placeholder='Phone Number'
                value={deliveryInfo.phone}
                onChange={handleInputChange}
                required
              />
            </div>
          </form>
        </div>

        {/* Payment Info */}
        <div className={styles.paymentInfo}>
          <h2>Payment</h2>
          <form onSubmit={handleSubmit}>
            <CardElement />
            <button
              type='submit'
              disabled={isProcessing || !stripe || !elements}
              className={styles.button}
            >
              {isProcessing ? 'Processing...' : 'Pay Now'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const CheckoutWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutDemo />
    </Elements>
  );
};

export default CheckoutWrapper;
