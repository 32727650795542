import React from 'react';
import hydrate from '../../../assets/hydrate.webp';
import ProductCard from '../../../components/ProductCard';

const RecomendedProducts = () => {
  return (
    <div className='flex flex-col items-center mx-auto px-6 md:px-12 py-12 bg-gray-50 rounded-lg '>
      <h2 className='text-2xl md:text-3xl font-semibold text-gray-800 mb-6 text-center'>
        You may also like
      </h2>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full'>
        <ProductCard
          imageUrl={hydrate}
          name='Hydrate Discovery Pack'
          price='75.00'
        />
        <ProductCard
          imageUrl={hydrate}
          name='Hydrate Discovery Pack'
          price='75.00'
        />
        <ProductCard
          imageUrl={hydrate}
          name='Hydrate Discovery Pack'
          price='75.00'
        />
        <ProductCard
          imageUrl={hydrate}
          name='Hydrate Discovery Pack'
          price='75.00'
        />
      </div>
    </div>
  );
};

export default RecomendedProducts;
