import React from "react";
import "./section7.css";

import APPLE from '../../../assets/6LYETS_RENDER_APPLE.png';
import LIME from '../../../assets/6LYETS_RENDER_LIME.png';
import ORANGE from '../../../assets/6LYETS_RENDER_ORANGE.png';
import WATERMELON from '../../../assets/6LYETS_RENDER_WATERMELON.png';

const BlogsData = [
  {
    id: 1,
    title: "Apple",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    link: "#", 
    img: APPLE,
  },
  {
    id: 2,
    title: "Lime",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    link: "#", 
    img: LIME,
  },
  {
    id: 3,
    title: "Watermelon",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    link: "#", 
    img: WATERMELON,
  },
  {
    id: 4,
    title: "Orange",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    link: "#", 
    img: ORANGE,
  },
];

const Section7 = () => {
  return (
    <section className="blogs-section">
      <div className="container7 pb-14">
        <h1 data-aos="fade-down" className="blogs-title">Blogs</h1>
        <div className="blogs-grid">
          {BlogsData.map((data) => (
            <div data-aos="zoom-in"  data-aos-delay="200" key={data.id} className="blog-card">
              <img src={data.img} alt={data.title} /> 
              <div className="blog-content">
                <h1 data-aos="fade-up" data-aos-delay={data.delay + 100} className="blog-title">{data.title}</h1>
                <p data-aos="fade-up" data-aos-delay={data.delay + 200} className="blog-desc">{data.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <button data-aos="fade-up" className="blogs-button">Read More</button>
      </div>
    </section>
  );
}

export default Section7;
