// src/components/PublicRoutes.js
import '../App.css';
import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LandingPage from '../Pages/LandingPage/LandingPage';
import AffiliateProgram from '../Pages/AffiliateProgram/AffiliateProgram';
// import Science from '../Pages/Learn/Science';
import FAQ from '../Pages/Learn/FAQ';
import AboutUs from '../Pages/Learn/AboutUs';
import Shop from '../Pages/Shop/Shop';
import Bundle from '../Pages/Bundle/Bundle';
import ContactUs from '../Pages/ContactUs/ContactUs';
import ChatBox from '../Pages/ChatBox/ChatBox';
import Product from '../Pages/Product/Product';
import ProductDetailPage from '../Pages/Product/ProductDetailPage';
import DummyCheckout from '../Pages/Testing/DummyCheckout';
import CartPage from 'Pages/Testing/Cart/Cart';
import TermsConditions from '../Pages/TermsConditions/TermsConditions';
import PrivacyPolicy from 'Pages/PirvacyPolicy/PrivacyPolicy';
import Cart from 'Pages/Cart/Cart';
import CheckoutForm from 'Pages/Checkout/CheckoutForm';
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from 'components/ScrollToTop';


const PublicRoutes = () => {
  const location = useLocation();

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  const isCheckoutRoute = location.pathname === '/checkout-form' || location.pathname === '/checkout';

  return (
    <>
      { <Header />}
      <div className='main-content'>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/affiliate-program' element={<AffiliateProgram />} />
          {/* <Route path='/science' element={<Science />} /> */}
          <Route path='/faq' element={<FAQ />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/shop' element={<Shop />} />
          <Route path='/bundle' element={<Bundle />} />
          <Route path='/contact-us' element={<ContactUs />} />
          {/* <Route path='/cart' element={<Cart />} /> */}
          <Route
            path='/collections/products/:id'
            element={<ProductDetailPage />}
          />

          <Route path='/bundle' element={<Bundle />} />
          <Route path='/ContactUs' element={<ContactUs />} />
          <Route path='/checkout-form'  element={<CheckoutForm />} />
          <Route path='/checkout' element={<DummyCheckout />} />
          <Route path='/cart' element={<CartPage />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path='/cart' element={<CartPage />} />
        </Routes>
      </div>
      <ChatBox />
      {<Footer />}
    </>
  );
};

export default PublicRoutes;
