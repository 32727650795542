// src/contexts/AdminAuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AxiosClient from 'utilities/axiosClient';

const AdminAuthContext = createContext();

export const AdminAuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(() => {
    // Initialize state with data from localStorage, if it exists
    const token = localStorage.getItem('adminToken');
    return token ? { token } : null;
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally, you could validate the token with a backend request or decode it here
    const token = localStorage.getItem('adminToken');
    if (token && !admin) {
      setAdmin({ token });
    }
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    try {
      const response = await AxiosClient.post('/admins/login', {
        email,
        password,
      });

      const data = response.data;

      if (data.token) {
        localStorage.setItem('adminToken', data.token);
        setAdmin({ email, role: data.role, token: data.token });
        navigate('/admin/secret-dashboard');
      } else {
        alert(data.error || 'Invalid credentials');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Failed to login. Please check your credentials and try again.');
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('adminToken');
    setAdmin(null);
    navigate('/admin/login');
  };

  return (
    <AdminAuthContext.Provider value={{ admin, login, logout, loading }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => useContext(AdminAuthContext);
