// ContactUsAdmin.js
import React, { useEffect, useState } from 'react';
import './ContactUs.css';
import AxiosClient from 'utilities/axiosClient';

const ContactUsAdmin = () => {
  const [contactMessages, setContactMessages] = useState([]);
  const [searchParams, setSearchParams] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const fetchContactMessages = async () => {
    try {
      const response = await AxiosClient.get('/contact/messages', {
        params: { ...searchParams, page, pageSize },
      });
      setContactMessages(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching contact messages:', error);
    }
  };

  useEffect(() => {
    fetchContactMessages();
  }, [searchParams, page, pageSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
    setPage(1); // Reset to first page on new search
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value, 10));
    setPage(1); // Reset to first page when page size changes
  };

  return (
    <div className='admin-contact-container'>
      <h2>Contact Messages</h2>

      {/* Search Filters */}
      <div className='search-filters'>
        <input
          type='text'
          name='name'
          placeholder='Search by Name'
          value={searchParams.name}
          onChange={handleInputChange}
        />
        <input
          type='text'
          name='email'
          placeholder='Search by Email'
          value={searchParams.email}
          onChange={handleInputChange}
        />
        <input
          type='text'
          name='message'
          placeholder='Search by Message Content'
          value={searchParams.message}
          onChange={handleInputChange}
        />
      </div>

      {/* Pagination Controls */}
      <div className='pagination-controls'>
        <label>
          Page Size:
          <select value={pageSize} onChange={handlePageSizeChange}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </label>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>

      <div className='contact-messages'>
        {contactMessages.length > 0 ? (
          contactMessages.map((msg) => (
            <div key={msg.id} className='contact-message'>
              <p>
                <strong>Name:</strong> {msg.fullName}
              </p>
              <p>
                <strong>Email:</strong> {msg.email}
              </p>
              <p>
                <strong>Message:</strong> {msg.message}
              </p>
              <p>
                <strong>Date:</strong>{' '}
                {new Date(msg.createdAt).toLocaleDateString()}
              </p>
            </div>
          ))
        ) : (
          <p>No contact messages available</p>
        )}
      </div>
    </div>
  );
};

export default ContactUsAdmin;
