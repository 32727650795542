import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import './CartSlider.css';

const CartSlider = () => {
  const { cart, isCartOpen, toggleCart, updateOrCreateCart } =
    useCart();
  const navigate = useNavigate();
  const handleCheckout = () => {
    navigate('/checkout-form', { state: { cartItems: cart } });
    toggleCart(); 
  };

  const handleDecreaseQuantity = (productId, currentQuantity) => {
    if (currentQuantity > 1) {
      updateOrCreateCart(productId, currentQuantity - 1);
    } else if (currentQuantity === 1) {
      updateOrCreateCart(productId, 0);
    }
  };

  const handleIncreaseQuantity = (productId, currentQuantity) => {
    updateOrCreateCart(productId, currentQuantity + 1);
  };

  const handleRemoveProduct = (productId) => {
    updateOrCreateCart(productId, 0); 
  };
  console.log(cart,"cart containing ====== >")
  return (
    <div className={`cart-slider ${isCartOpen ? 'open-up' : ''}`}>
      <div className='cart-slider-header'>
        <h2>Your Cart</h2>
        <button className='close-btn-CS' onClick={toggleCart}>
          <FontAwesomeIcon icon={faXmark} size="sm" />
        </button>
      </div>
        <div className="slider-underline"></div>
      <div className='cart-slider-content'>
        {cart.length === 0 ? (
          <p>Your cart is empty!</p>
        ) : (
          <ul className='cart-items-list'>
            {cart.map((item) => (
              <li key={item.productId} className='cart-item'>
                <div className='product-info'>
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className='product-image-CS'
                  />
                  <div className='product-detail-CS'>
                    <div className='name-price'>
                      <p className='product-name'>{item.name}</p>
                      <div className='item-price'>
                      {item.currency} {(item.price * item.quantity).toFixed(2)}
                      </div>
                    </div>
                    {/* <p className='product-size'>Size: {item.product.size}</p> */}
                    <div className='quantity-remove'>
                      <div className='quantity-controls-CS'>
                        <button
                          className='quantity-btn-CS'
                          onClick={() =>
                            handleDecreaseQuantity(
                              item.productId,
                              item.quantity,
                            )
                          }
                          // disabled={item.quantity <= 1}
                        >
                          -
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          className='quantity-btn-CS'
                          onClick={() =>
                            handleIncreaseQuantity(
                              item.productId,
                              item.quantity,
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                      <button
                        className='remove-btn'
                        onClick={() => handleRemoveProduct(item.productId)}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className='cart-total-section'>
        <div className='total-price'>
          <p className='totaltext'>Subtotal:</p>
          <p className='totalnumber'>
            Rs.{' '}
            {cart
              .reduce(
                (total, item) => total + item.price * item.quantity,
                0,
              )
              ?.toFixed(2)}{' '}
          </p>
        </div>
        <button className='checkout-btn-CS' onClick={handleCheckout}>
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
};

export default CartSlider;
