import React, { useState } from 'react';
import {
  FaChevronDown,
  FaChevronUp,
  FaInfoCircle,
  FaHeart,
  FaStar,
  FaUtensils,
} from 'react-icons/fa';

const Accordion = () => {
  const [openAccordions, setOpenAccordions] = useState([0]);

  const toggleAccordion = (index) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const accordionData = [
    {
      title: 'Description',
      icon: <FaInfoCircle className='text-blue-500 mr-2' />,
      content: `
        Our hydration products are made for everyone! Adults and children 4 or older.
        What's in them:
        - All 6 essential electrolytes (Na, K, Mg, Ca, P, Cl)
        - 4 Vitamins (C, B5, B6, B12)
        - Zinc
        - Real fruit flavors
        - No sugar | No additives | No preservatives
        - Non-GMO | Vegan | Halal | Kosher | Gluten-Free | Keto & Paleo safe
      `,
    },
    {
      title: 'Benefits',
      icon: <FaHeart className='text-pink-500 mr-2' />,
      content: `
        Experience better hydration than water alone with oneshot. Our science-backed blend 
        of essential electrolytes, vitamins, and nutrients was created to replenish your body better.

        Each oneshot stick delivers triple the electrolytes of traditional sports drinks and includes 
        a blend of vitamins and minerals that will help improve your performance, endurance, and recovery.
      `,
    },
    {
      title: 'How to Use',
      icon: <FaUtensils className='text-green-500 mr-2' />,
      content: (
        <ul className='list-disc pl-5'>
          <li>Tear & pour</li>
          <li>Add cold water</li>
          <li>Stir and enjoy</li>
          <p className='mt-2'>
            *We recommend using 330-500 ml cold water per stick.
          </p>
        </ul>
      ),
    },
    {
      title: 'Nutritions',
      icon: <FaStar className='text-yellow-500 mr-2' />,
      content: `
        Sodium Carbonate, Trisodium Citrate, Tricalcium Phosphate, Magnesium Hydroxide Carbonate,
        Potassium Chloride, Vitamin C, Vitamin B5, Vitamin B6, Vitamin B12, Zinc Gluconate,
        Natural Flavor, Stevia Leaf Extract (Steviol Glycosides), Maltodextrin, Citric Acid, 
        Beta-Carotene (Color), Anthocyanins (Color).

        Vegan | Dairy Free | Gluten Free.
      `,
    },
  ];

  return (
    <div className='mt-6 shadow-lg rounded-lg bg-white'>
      {accordionData.map((item, index) => (
        <div key={index} className='border-t border-gray-200'>
          <div
            className='flex items-center justify-between p-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200'
            onClick={() => toggleAccordion(index)}
          >
            <div className='flex items-center'>
              {item.icon}
              <span className='text-lg font-semibold text-gray-700'>
                {item.title}
              </span>
            </div>
            {openAccordions.includes(index) ? (
              <FaChevronUp className='text-gray-700 transition-transform duration-200 ease-in-out' />
            ) : (
              <FaChevronDown className='text-gray-700 transition-transform duration-200 ease-in-out' />
            )}
          </div>
          <div
            className={`overflow-hidden transition-[max-height] duration-300 ease-in-out ${
              openAccordions.includes(index) ? 'max-h-screen' : 'max-h-0'
            }`}
          >
            <div className='p-4 text-gray-600 whitespace-pre-line'>
              {item.content}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
