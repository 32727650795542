import React, { useState, useRef, useEffect } from 'react';
import { FaCommentDots, FaTimes, FaPaperPlane, FaBox } from 'react-icons/fa';
import { useChat } from '../../contexts/ChatContext';
import './ChatBox.css';

const ChatBox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isTrackingOrder, setIsTrackingOrder] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [email, setEmail] = useState('');
  const chatBodyRef = useRef(null);
  const { sendMessage, getChat, socket, trackOrder } = useChat();
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [typing, setTyping] = useState(false);

  // Auto scroll to the latest message
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (isOpen) {
      const fetchChat = async () => {
        const chatData = await getChat();
        setMessages(chatData?.messages || []);
        socket.emit('joinRoom', { chatRoomId: chatData[0]?.chatRoomId });
      };
      fetchChat();
    }
  }, [isOpen, getChat, socket]);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom(); // Scroll to the bottom whenever the chat is opened
    }
  }, [isOpen]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom(); // Scroll to the bottom whenever new messages are added
    }
  }, [messages]);

  useEffect(() => {
    socket.on('newMessage', (message) => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, message];
        return updatedMessages;
      });
    });

    socket.on('typing', (data) => {
      setTyping(data.isTyping);
    });

    return () => {
      socket.off('newMessage');
      socket.off('typing');
    };
  }, [socket]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      sendMessage(inputMessage, 'USER');
      const messageData = { text: inputMessage, senderType: 'USER' };
      socket.emit('newMessage', messageData);
      setMessages((prev) => [...prev, messageData]);
      setInputMessage('');
    }
  };

  const handleTrackOrder = () => {
    setIsTrackingOrder(true);
    sendMessage('I would like to track my order.', 'USER');
    sendMessage(
      'Please provide your Order ID and email address to proceed.',
      'ADMIN',
    );
    setMessages((prev) => [
      ...prev,
      { senderType: 'USER', text: 'I would like to track my order.' },
      {
        senderType: 'ADMIN',
        text: 'Please provide your Order ID and email address to proceed.',
      },
    ]);
  };

  const handleSubmitTracking = async (e) => {
    e.preventDefault();
    if (orderId && email) {
      setMessages((prev) => [
        ...prev,
        { senderType: 'USER', text: `Order ID: ${orderId}, Email: ${email}` },
      ]);
      sendMessage(`Order ID: ${orderId}, Email: ${email}`, 'USER');
      const trackingResponse = await trackOrder(
        orderId,
        email,
        messages[0].chatRoomId,
      );
      if (
        trackingResponse?.trackingHistory &&
        trackingResponse.trackingHistory.length > 0
      ) {
        trackingResponse.trackingHistory.forEach((trackInfo) => {
          const {
            trackingNumber,
            status,
            location,
            estimatedDeliveryFrom,
            estimatedDeliveryTo,
          } = trackInfo;

          const trackingMessage = `Order found: Tracking Number ${trackingNumber}, Status: ${status}, Location: ${
            location || 'N/A'
          }, Estimated Delivery: ${estimatedDeliveryFrom || 'N/A'} to ${
            estimatedDeliveryTo || 'N/A'
          }.`;

          sendMessage(trackingMessage, 'ADMIN');
          setMessages((prev) => [
            ...prev,
            {
              senderType: 'ADMIN',
              text: trackingMessage,
            },
          ]);
        });
      } else {
        sendMessage('Order not found with the provided information.', 'ADMIN');
        setMessages((prev) => [
          ...prev,
          {
            senderType: 'ADMIN',
            text: 'Order not found with the provided information.',
          },
        ]);
      }

      setIsTrackingOrder(false);
      setOrderId('');
      setEmail('');
    }
  };

  const handleCancelTracking = () => {
    setIsTrackingOrder(false);
    setOrderId('');
    setEmail('');
    sendMessage('Order tracking request canceled.', 'USER');
    setMessages((prev) => [
      ...prev,
      {
        senderType: 'ADMIN',
        text: 'Order tracking canceled. How else can I assist you?',
      },
    ]);
  };

  const chatRoomId = messages[0]?.chatRoomId;

  return (
    <div className='chat-box'>
      <button className='chat-toggle' onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <FaTimes /> : <FaCommentDots />}
      </button>

      {isOpen && (
        <div className='chat-window'>
          <div className='chat-header'>Chat with us</div>
          <div className='chat-body' ref={chatBodyRef}>
            {messages &&
              messages.map((message, index) => (
                <div
                  key={index}
                  className={`message-bubble ${message.senderType}-bubble`}
                >
                  {message.text}
                </div>
              ))}
          </div>
          <div className='chat-input-area'>
            {isTrackingOrder ? (
              <form onSubmit={handleSubmitTracking} className='tracking-form'>
                <input
                  type='text'
                  placeholder='Enter Order ID'
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                  required
                />
                <input
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className='button-group'>
                  <button type='submit' className='track-order-btn'>
                    <FaBox /> Track Order
                  </button>
                  <button
                    type='button'
                    className='cancel-btn'
                    onClick={handleCancelTracking}
                  >
                    <FaTimes /> Cancel
                  </button>
                </div>
              </form>
            ) : (
              <>
                {typing && <p className='typing-indicator'>User is typing...</p>}
                <button className='track-order-btn' onClick={handleTrackOrder}>
                  <FaBox /> Track Order
                </button>

                <div className='message-input-container'>
                  <textarea
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    placeholder='Type your message...'
                    rows={1}
                    className='message-input'
                  />
                  <button className='send-btn' onClick={handleSendMessage}>
                    <FaPaperPlane />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
