import React, { useState, useEffect } from 'react';
import { useChat } from '../../../contexts/ChatContext';
import ChatRoomList from '../../../components/Admin/Chat/ChatRoomList/ChatRoomList';
import ChatRoom from '../../../components/Admin/Chat/ChatRoom/ChatRoom';
import './Chat.css';

const AdminChatPage = () => {
  const { chats, allMessages, sendMessage, getChatRooms, getChat } = useChat();
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);

  useEffect(() => {
    getChatRooms(); // Fetch chat rooms when page loads
  }, [getChatRooms]);

  // Handle selection of a chat room
  const handleChatSelect = (chatRoomId) => {
    setSelectedChatRoom(chatRoomId); // Update selected chat room
    getChat(chatRoomId); // Fetch messages for the selected chat room
    console.log(chatRoomId,"chatRoomId")
  };

  return (
    <div className='admin-chat-container'>
      <ChatRoomList onSelectChat={handleChatSelect} />
      {selectedChatRoom && (
        <ChatRoom
          messages={allMessages}
          sendMessage={sendMessage}
          chatRoomId={selectedChatRoom}
        />
      )}
    </div>
  );
};

export default AdminChatPage;
