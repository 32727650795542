// import './App.css';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Navigate,
// } from 'react-router-dom';
// import { AdminAuthProvider, useAdminAuth } from './contexts/AdminAuthContext';

// import Header from './components/Header';
// import Footer from './components/Footer';
// import AffiliateProgram from './Pages/AffiliateProgram/AffiliateProgram';
// import LandingPage from './Pages/LandingPage/LandingPage';
// import Science from './Pages/Learn/Science';
// import FAQ from './Pages/Learn/FAQ';
// import AboutUs from './Pages/Learn/AboutUs';
// import Shop from './Pages/Shop/Shop';
// import Bundle from './Pages/Bundle/Bundle';
// import ChatBox from './Pages/ChatBox/ChatBox';
// import ContactUs from './Pages/ContactUs/ContactUs';

// import AdminLogin from './Pages/Admin/login/AdminLogin';
// import Dashboard from './Pages/Admin/Dashboard/Dashboard';

// // Private route component to restrict access to authenticated admins only
// const AdminRoute = ({ children }) => {
//   const { admin } = useAdminAuth();
//   return admin ? children : <Navigate to='/admin/login' />;
// };

// function App() {
//   return (
//     <Router>
//       <AdminAuthProvider>
//         <div className='App'>
//           <Header />
//           <div className='main-content'>
//             <Routes>
//               <Route path='/' element={<LandingPage />} />
//               <Route path='/affiliate-program' element={<AffiliateProgram />} />
//               <Route path='/science' element={<Science />} />
//               <Route path='/faq' element={<FAQ />} />
//               <Route path='/about-us' element={<AboutUs />} />
//               <Route path='/shop' element={<Shop />} />
//               <Route path='/bundle' element={<Bundle />} />
//               <Route path='/contact-us' element={<ContactUs />} />

//               {/* Admin Routes */}
//               <Route path='/admin/login' element={<AdminLogin />} />
//               <Route
//                 path='/admin/secret-dashboard'
//                 element={
//                   <AdminRoute>
//                     <Dashboard />
//                   </AdminRoute>
//                 }
//               />
//             </Routes>
//           </div>

//           <ChatBox />

//           <Footer />
//         </div>
//       </AdminAuthProvider>
//     </Router>
//   );
// }

// export default App;

// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdminAuthProvider } from './contexts/AdminAuthContext';

import PublicRoutes from './routes/publicRoutes';
import AdminRoutes from './routes/adminRoutes';
import { CartProvider } from './contexts/CartContext';
import { ChatProvider } from 'contexts/ChatContext';

function App() {
  return (
    <Router>
      <AdminAuthProvider>
        <CartProvider>
          <ChatProvider>
            <Routes>
            
              {/* Public Routes: Include landing page, shop, etc. */}
              <Route path='/*' element={<PublicRoutes />} />
              {/* Admin Routes: Includes login and dashboard */}
              <Route path='/admin/*' element={<AdminRoutes />} />
            </Routes>
          </ChatProvider>
        </CartProvider>
      </AdminAuthProvider>
    </Router>
  );
}

export default App;
