import React from 'react';

const ProductCard = ({ imageUrl, name, price }) => {
  return (
    <div className='bg-white rounded-lg text-center  hover:shadow-xl cursor-pointer'>
      <div className='flex justify-center items-center mb-4'>
        <img
          src={imageUrl}
          alt={name}
          className='w-auto h-auto object-cover rounded-md'
        />
      </div>
      <h3 className='text-lg font-semibold text-gray-700'>{name}</h3>
      <p className='text-lg text-gray-600'>Dhs. {price}</p>
    </div>
  );
};

export default ProductCard;
