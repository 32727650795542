import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './EditOrder.css';
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';

const EditOrder = () => {
  const { orderId } = useParams();
  const [trackingId, setTrackingId] = useState('');
  const history = useNavigate();
  const { admin } = useAdminAuth();

  // Fetch the order details to prefill tracking ID (if available)
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await AxiosClient.get(`/admin/orders/${orderId}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admin.token}`,
          },
        });
        setTrackingId(response.data.trackingId || '');
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [orderId]);

const handleUpdate = async (event) => {
  event.preventDefault();

  try {
    await AxiosClient.put(
      `/orders/${orderId}`,
      { trackingId },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${admin.token}`, // Assuming you have the admin token
        },
      },
    );
    history.push(`/admin/orders/${orderId}`); // Use history.push for navigation
  } catch (error) {
    console.error('Error updating order:', error);
  }
};


  return (
    <div className='edit-order'>
      <h1 className='editOrder-h1'>Edit Order - ID {orderId}</h1>
      <form onSubmit={handleUpdate} className='editOrder-form'>
        <label htmlFor='trackingId' className='editOrder-label'>
          Tracking ID:
        </label>
        <input
          type='text'
          id='trackingId'
          value={trackingId}
          className='editOrder-input'
          onChange={(e) => setTrackingId(e.target.value)}
        />
        <button type='submit' className='editOrder-button'>
          Update Order
        </button>
      </form>
    </div>
  );
};

export default EditOrder;
