import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import './Topbar.css';
import { FiUser } from 'react-icons/fi';
import { useAdminAuth } from '../../../contexts/AdminAuthContext';

const Topbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAdminAuth();

  const handleProfileClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null); // Close if already open
    } else {
      setAnchorEl(event.currentTarget); // Open dropdown
    }
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the dropdown
  };

  const handleLogout = () => {
    logout(); // Call logout function
    handleClose(); // Close the dropdown after logout
  };

  return (
    <nav className="navbar">
      {/* Removed the menu icon for toggling the sidebar */}
      <div className='profile' onClick={handleProfileClick}>
        <FiUser className='profileIcon' />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)} // Open menu if anchorEl is set
          onClose={handleClose} // Close on clicking outside or an item
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          disableScrollLock // Disable scroll lock when menu is open
        >
          <MenuItem onClick={handleClose}>View Profile</MenuItem>
          <MenuItem onClick={handleClose}>Account Settings</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </nav>
  );
};

export default Topbar;
