import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminOrder.css'; // Import CSS file for styling
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const { admin } = useAdminAuth();

  // Fetch all orders from backend
  useEffect(() => {
    console.log(admin,"admin infoo")
    const fetchOrders = async () => {
      try {
        const response = await AxiosClient.get('/orders', {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admin.token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className='admin-orders'>
      <h1>All Orders</h1>
      <table className='orders-table'>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>User</th>
            <th>Total Amount</th>
            <th>Status</th>
            <th>Tracking ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.user?.email || 'Guest'}</td>
              <td>{order.totalAmount}</td>
              <td>{order.status}</td>
              <td>{order.trackingId || 'N/A'}</td>
              <td>
                <Link to={`/admin/secret-dashboard/orders/${order.id}`}>
                  View
                </Link>
                <Link to={`/admin/secret-dashboard/orders/edit/${order.id}`}>
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminOrders;
