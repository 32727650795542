import React from 'react';
import banner1 from '../../../assets/1.jpg';
import banner1Mobile from '../../../assets/1 B.jpg'; 

const Section6 = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <div className='banner-container'>
      <img 
        className='banner' 
        data-aos="fade-up" 
        src={isMobile ? banner1Mobile : banner1} 
        alt='banner' 
      />
    </div>
  );
}

export default Section6;
