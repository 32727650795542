import React, { useState } from 'react';
import './Dashboard.css';
import BoxInfo from '../../../components/Admin/BoxInfo/BoxInfo';
import Table from '../../../components/Admin/Table/Table';
import TodoList from '../../../components/Admin/TodoList/TodoList';
import Sidebar from '../../Admin/Sidebar/Sidebar';
import Topbar from '../../Admin/Topbar/Topbar';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='dashboard'>
      {/* <Sidebar isOpen={isSidebarOpen} /> */}
      <div className={`dashboardContent ${isSidebarOpen ? 'open' : 'closed'}`}>
        {/* <Topbar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} /> */}
        <div className='mainContent'>
          <div className='headTitle'>
            <h1>Dashboard</h1>
            <button className='downloadBtn'>Download PDF</button>
          </div>
          <BoxInfo />
          <div className='content'>
            <Table />
            <TodoList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
