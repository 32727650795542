import React from 'react';
import { FaPlane, FaHeadphonesAlt, FaWallet, FaCheckCircle } from 'react-icons/fa';
import './Section10.css';

const ServiceData = [
    {
        id: 1,
        icon: <FaPlane className="icon" />,
        title: "Free Shipping",
        description: "Free Shipping On All Orders",
    },
    {
        id: 2,
        icon: <FaCheckCircle className="icon" />,
        title: "Safe Money",
        description: "30 Days Money Back",
    },
    {
        id: 3,
        icon: <FaWallet className="icon" />,
        title: "Secure Payment",
        description: "100% Secure Payment",
    },
    {
        id: 4,
        icon: <FaHeadphonesAlt className="icon" />,
        title: "24/7 Support",
        description: "Dedicated Support",
    }
];

const Section10 = () => {
    return (
        <div className="section10-container">
            <div className="service-grid">
                {ServiceData.map((data) => (
                    <div data-aos="fade-up" key={data.id} className="service-item">
                        <div>
                        {data.icon}
                        </div>
                        <div>
                            <h1 className="service-title">{data.title}</h1>
                            <p className="service-description">{data.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Section10;
