import React from "react";
import Product from "./Product";
import HowToUse from "./_components/HowToUse";
import RecomendedProducts from "./_components/RecomendedProducts";
import ProductPromise from "./_components/ProductPromise";
import banner1 from "../../assets/banner1.png";
import banner2 from "../../assets/banner2.png";
import FollowUs from "./_components/FollowUs";

const ProductDetailPage = () => {
  return (
    <div className="flex flex-col ">
      <Product />
      <RecomendedProducts />
      {/* <HowToUse />
      <div className="my-16">
        <img className="w-full h-full object-cover" src={banner1} alt="" />
      </div>
      <ProductPromise />
      <div className="my-16">
        <img className="w-full h-full object-cover" src={banner2} alt="" />
      </div>
      <FollowUs/> */}
    </div>
  );
};

export default ProductDetailPage;
