import React, { useState } from 'react';
import './Sidebar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  AiOutlineShopping,
  AiOutlineDown,
} from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import {
  faBoxOpen,
  faShop,
  faTruckFast,
  faArrowRightFromBracket,
  faChartLine,
  faContactCard,
} from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ isOpen }) => {
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleProducts = () => setIsProductsOpen(!isProductsOpen);
  const toggleAnalytics = () => setIsAnalyticsOpen(!isAnalyticsOpen);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/login');
  };

  return (
    <aside className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <a href='#' className='brand'>
        {isOpen && <span className='text'>6LYETS ADMIN</span>}
      </a>
      <ul className='menu top'>
        <li>
          <NavLink to='/admin/secret-dashboard' activeClassName='active'>
            <FontAwesomeIcon icon={faChartLine} className='dashboard-icon' />
            {isOpen && <span className='text'>Dashboard</span>}
          </NavLink>
        </li>
        <li>
          <div className='menu-item' onClick={toggleProducts}>
            <FontAwesomeIcon icon={faShop} className='products-icon' />
            {isOpen && <span className='text'>My Products</span>}
            {isOpen && (
              <AiOutlineDown
                className={`dropdown-icon ${isProductsOpen ? 'rotated' : ''}`}
              />
            )}
          </div>
          {isProductsOpen && (
            <ul className='submenu'>
              <li>
                <NavLink to='/admin/secret-dashboard/products/add'>
                  <span className='text'>Add Product</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/admin/secret-dashboard/products'>
                  <span className='text'>View Products</span>
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <div className='menu-item' onClick={toggleAnalytics}>
            <FontAwesomeIcon icon={faBoxOpen} className='orders-icon' />
            {isOpen && <span className='text'>Orders</span>}
            {isOpen && (
              <AiOutlineDown
                className={`dropdown-icon ${isAnalyticsOpen ? 'rotated' : ''}`}
              />
            )}
          </div>
          {isAnalyticsOpen && (
            <ul className='submenu'>
              <li>
                <NavLink to='/admin/secret-dashboard/orders'>
                  <span className='text'>View Orders</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/admin/analytics/reports'>
                  <span className='text'>Reports</span>
                </NavLink>
              </li>
            </ul>
          )}
        </li>
      </ul>
      <ul className='menu'>
        <li>
          <NavLink to='/admin/secret-dashboard/live-chat'>
            <FontAwesomeIcon icon={faMessage} className='chat-icon' />
            {isOpen && <span className='text'>Live Chat</span>}
          </NavLink>
        </li>
        <li>
          <NavLink to='/admin/secret-dashboard/tracking'>
            <FontAwesomeIcon icon={faTruckFast} className='settings-icon' />
            {isOpen && <span className='text'>Tracking</span>}
          </NavLink>
        </li>
        <li>
          <NavLink to='/admin/secret-dashboard/contactus'>
            <FontAwesomeIcon
              icon={faContactCard}
              className='fa fa-address-book'
            />
            {isOpen && <span className='text'>Contact-Us</span>}
          </NavLink>
        </li>
        <li>
          <NavLink to='/admin/login' className='logout' onClick={handleLogout}>
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className='logout-icon'
            />
            {isOpen && <span className='text'>Logout</span>}
          </NavLink>
        </li>
      </ul>
      <div className='bottomLine'></div>
    </aside>
  );
};

export default Sidebar;
