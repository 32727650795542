import React, { useState } from 'react';
import { useAdminAuth } from '../../../contexts/AdminAuthContext';
import './AdminLogin.css'; 
import logo from '../../../assets/logo.png';  // Import the logo image
import line from '../../../assets/line.png';  // Import the line image
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import eye icons

const AdminLogin = () => {
  const { login, loading } = useAdminAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className='admin-login'>
      <div className='login-left'>
        <img src={logo} alt='6LYETS Logo' /> {/* Use imported logo */}
      </div>
      <div className='divider'>
        <img src={line} alt='Divider Line' /> {/* Use imported line image */}
      </div>
      <div className='login-right'>
        <h2>Welcome</h2>
        <p>Please login to Admin Dashboard.</p>
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            placeholder='Username'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className='password-container'>
            <input
              type={showPassword ? 'text' : 'password'} // Toggle between text and password type
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span 
              className='toggle-password' 
              onClick={() => setShowPassword(!showPassword)} // Toggle the showPassword state
            >
              {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />} {/* Toggle icon */}
            </span>
          </div>
          <button type='submit' disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
