import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  FiDroplet,
  FiStar,
  FiUsers,
  FiCpu,
  FiThermometer,
  FiZap,
} from "react-icons/fi";
import "./AboutUs.css";
import capsuleImage from "../../assets/Capsule-Box-Mockup4.png"; // Import the image

// Define the AboutUsCTAButton component
function AboutUsCTAButton() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/affiliate-program');
  };

  return (
    <button className="about-us-cta-button" onClick={handleButtonClick}>
      Join the 6Lytes Community
    </button>
  );
}

const AboutUs = () => {
  const values = [
    {
      icon: <FiDroplet />,
      title: "Purity",
      description:
        "We carefully source only the cleanest, highest-quality ingredients to ensure you receive the best in every product.",
    },
    {
      icon: <FiStar />,
      title: "Excellence",
      description:
        "Our commitment to rigorous quality standards guarantees that our products are effective, reliable, and easy to use.",
    },
    {
      icon: <FiUsers />,
      title: "Customer-Centricity",
      description:
        "We are dedicated to enhancing your wellness journey, providing solutions that truly benefit your lifestyle and goals.",
    },
  ];

  const benefits = [
    { icon: <FiCpu />, text: "Cognitive Boost" },
    { icon: <FiThermometer />, text: "Thermal Relief" },
    { icon: <FiDroplet />, text: "Dehydration Defense" },
    { icon: <FiZap />, text: "Energy Renewal" },
  ];

  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <div  data-aos-once="true" data-aos="zoom-in" className="about-us-content">
          <h1>About 6Lytes</h1>
          <p>Empowering Change Through Community</p>
        </div>
      </div>

      <main className="about-us-main">
        <section data-aos-once="true" data-aos="fade-down" className="about-us-mission">
          <h2>Our Mission</h2>
          <p>
            At 6Lytes, we believe in the extraordinary power of community to
            drive personal transformation. We are dedicated to elevating each
            individual by providing premium support and clean, effective
            products. With a focus on zero-junk ingredients, we empower you to
            embrace your journey and overcome daily challenges.
          </p>
        </section>

        <section data-aos-once="true" data-aos="fade-down" className="about-us-values">
          <h2>Our Values</h2>
          <div className="about-us-values-grid">
            {values.map((value, index) => (
              <div key={index} className="about-us-value-card">
                <span className="about-us-value-icon">{value.icon}</span>
                <h3>{value.title}</h3>
                <p>{value.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section data-aos-once="true" data-aos="fade-down" className="about-us-products">
          <h2>Our Products</h2>
          <div className="about-us-product-showcase">
            <div className="about-us-product-image">
              <img src={capsuleImage} alt="Hydrate Product" />{" "}
              {/* Use imported image */}
            </div>
            <div className="about-us-product-info">
              <h3>Introducing Hydrate</h3>
              <p>
                Our premium electrolyte hydration powder designed for everyday
                use. Packed with all six essential electrolytes, four vital
                vitamins, and zinc, Hydrate replenishes what your body loses
                throughout the day.
              </p>
              <div className="about-us-product-benefits">
                {benefits.map((benefit, index) => (
                  <div key={index} className="about-us-benefit">
                    <span className="about-us-benefit-icon">
                      {benefit.icon}
                    </span>
                    <span>{benefit.text}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section data-aos-once="true" data-aos="zoom-in" className="about-us-community">
          <h2>Join Our Community</h2>
          <p>
            6Lytes is more than just a supplement brand. We're a community of
            individuals committed to living healthier, more active lives. We're
            here to support you every step of the way on your wellness journey.
          </p>
          {/* Use the AboutUsCTAButton component here */}
          <AboutUsCTAButton />
        </section>

        <section data-aos-once="true" data-aos="fade-up" className="about-us-closing">
          <h2>Together We Thrive</h2>
          <p>
            Join us in creating a vibrant community where everyone is inspired
            to pursue their passions and unlock their true potential. Together,
            we can build a brighter future, one step at a time.
          </p>
        </section>
      </main>
    </div>
  );
};

export default AboutUs;
