import React, { useState, useEffect, useCallback } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaMinus,
  FaShoppingCart,
  FaMoneyBillWave,
  FaCreditCard,
} from 'react-icons/fa';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import hydrate from '../../assets/hydrate.webp';
import ProductHeader from './_components/ProductHeader';
import Accordian from './_components/Accordian';
import { useParams } from 'react-router-dom';
import AxiosClient from 'utilities/axiosClient';
import { useCart } from 'contexts/CartContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const fallbackProduct = {
  name: 'Hydrate Discovery Pack',
  priceAED: 75.0,
  pricePKR: 6000.0, // Example price in PKR
  description:
    'Zero-sugar blend of 6 essential electrolytes, 4 vitamins, and Zinc.',
  stock: 10,
  productImages: [
    { imageUrl: hydrate },
    { imageUrl: hydrate },
    { imageUrl: hydrate },
  ],
};

const Product = () => {
  const { id } = useParams();
  const { cart, updateOrCreateCart, toggleCart } = useCart();
  const [product, setProduct] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [buyingOption, setBuyingOption] = useState('cashOnDelivery');
  const [currency, setCurrency] = useState('AED');
  const [price, setPrice] = useState(fallbackProduct.priceAED);

  const fetchProduct = useCallback(async () => {
    try {
      const userTimezone = dayjs.tz.guess();

      const response = await AxiosClient.get(`/products/${id}`, {
        params: {
          timezone: userTimezone,
        },
      });
      if (response.data) {
        setProduct(response.data);
        determineCurrency(response.data); // Determine currency after setting the product data
      } else {
        setProduct(fallbackProduct);
      }
    } catch (error) {
      console.error('Error fetching product:', error);
      setProduct(fallbackProduct);
      determineCurrency(fallbackProduct); // Determine currency in case of fallback
    }
  }, [id]);

  const determineCurrency = (productData) => {
    const userTimezone = dayjs.tz.guess();
    console.log(userTimezone, 'user timezone');
    if (userTimezone.includes('Asia/Karachi')) {
      setCurrency('PKR');
      setPrice(productData.pricePKR);
    } else {
      setCurrency('AED');
      setPrice(productData.priceAED);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const handleNextImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % product.productImages.length,
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? product.productImages.length - 1 : prevIndex - 1,
    );
  };

  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 1) {
      setQuantity(value);
    }
  };

  const handleAddToCart = () => {
    if (product) {
      const existingCartItem = cart.find(
        (item) => item.productId === product.id,
      );

      const newQuantity = existingCartItem ? existingCartItem.quantity + 1 : 1;
      updateOrCreateCart(product.id, quantity);
      toggleCart();
    }
  };

  const handleBuyingOptionChange = (e) => {
    setBuyingOption(e.target.value);
  };

  if (!product) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <p className='text-xl text-gray-600'>Loading product details...</p>
      </div>
    );
  }

  return (
    <div className='max-w-7xl mx-auto px-4 py-8'>
      <ProductHeader />

      <div className='flex flex-col lg:flex-row gap-8 mt-8'>
        {/* Image Gallery */}
        <div className='flex flex-col lg:w-2/3'>
          {/* Main Image */}
          <div className='relative overflow-hidden max-w-lg mx-auto h-[500px] rounded-md shadow-lg'>
            <img
              src={
                product.productImages[currentImageIndex]?.imageUrl || hydrate
              }
              alt={`Product Image ${currentImageIndex + 1}`}
              className='w-full h-full object-contain'
            />
            {/* Navigation Buttons */}
            <button
              onClick={handlePreviousImage}
              className='absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-70 p-2 rounded-full shadow hover:bg-opacity-100 transition duration-200'
              aria-label='Previous Image'
            >
              <FaArrowLeft size={20} />
            </button>
            <button
              onClick={handleNextImage}
              className='absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-70 p-2 rounded-full shadow hover:bg-opacity-100 transition duration-200'
              aria-label='Next Image'
            >
              <FaArrowRight size={20} />
            </button>
          </div>

          {/* Thumbnails */}
          <div className='flex gap-4 mt-6 overflow-x-auto ml-52'>
            {product.productImages.map((img, idx) => (
              <img
                key={idx}
                src={img.imageUrl || hydrate}
                alt={`Product thumbnail ${idx + 1}`}
                className={`w-20 h-20 object-cover rounded-md cursor-pointer border ${
                  idx === currentImageIndex
                    ? 'border-indigo-500'
                    : 'border-transparent'
                } transition duration-200`}
                onClick={() => setCurrentImageIndex(idx)}
                loading='lazy'
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = hydrate;
                }}
              />
            ))}
          </div>
        </div>

        {/* Product Details */}
        <div className='lg:w-1/3 flex flex-col'>
          {/* Title, Reviews, Price */}
          <div className='mb-4'>
            <h1 className='text-3xl font-bold text-gray-800 mb-2'>
              {product.name}
            </h1>
            <p className='text-sm text-gray-500 mb-2'>⭐⭐⭐⭐⭐ 9 reviews</p>
            <p
              className='text-2xl font-semibold text-indigo-600 mb-4'
              style={{ color: '#004d61' }}
            >
              {currency} {product.price?.toFixed(2)}
            </p>
            <p className='text-lg text-gray-700'>{product.description}</p>
          </div>

          {/* Payment Options */}
          <div className='mb-6'>
            <label className='block font-bold text-md text-gray-700 mb-2'>
              Payment Options
            </label>
            <div className='grid grid-cols-2 gap-5'>
              <label className='flex items-center justify-center py-3 rounded-md cursor-pointer transition-all duration-200 hover:border-indigo-500'>
                <input
                  type='radio'
                  name='buyingOption'
                  value='cashOnDelivery'
                  checked={buyingOption === 'cashOnDelivery'}
                  onChange={handleBuyingOptionChange}
                  className='h-5 w-5 text-indigo-600 mr-2'
                />
                <span className='text-gray-700 font-medium flex items-center'>
                  <FaMoneyBillWave className='mr-2' /> Cash on Delivery
                </span>
              </label>
              <label className='flex items-center justify-center py-3 rounded-md cursor-pointer transition-all duration-200 hover:border-indigo-500'>
                <input
                  type='radio'
                  name='buyingOption'
                  value='throughCard'
                  checked={buyingOption === 'throughCard'}
                  onChange={handleBuyingOptionChange}
                  className='h-5 w-5 text-indigo-600 mr-2'
                />
                <span className='text-gray-700 font-medium flex items-center'>
                  <FaCreditCard className='mr-2' /> Through Card
                </span>
              </label>
            </div>
          </div>

          {/* Quantity Selector */}
          <div className='mb-6'>
            <label
              htmlFor='quantity'
              className='block text-md font-medium text-gray-700 mb-2'
            >
              Quantity
            </label>
            <div className='flex gap-2 mx-auto rounded-md overflow-hidden'>
              <button
                onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
                className='w-10 h-10 bg-gray-100 text-gray-700 hover:bg-gray-200 transition duration-200 flex items-center justify-center'
                aria-label='Decrease quantity'
              >
                <FaMinus />
              </button>
              <input
                type='number'
                id='quantity'
                value={quantity}
                onChange={handleQuantityChange}
                className='w-10 h-10 text-center text-lg font-semibold bg-white focus:outline-none focus:ring-0'
                min='1'
              />
              <button
                onClick={() => setQuantity((prev) => prev + 1)}
                className='w-10 h-10 bg-gray-100 text-gray-700 hover:bg-gray-200 transition duration-200 flex items-center justify-center'
                aria-label='Increase quantity'
              >
                <FaPlus />
              </button>
            </div>
          </div>

          {/* Action Buttons */}
          <div className='flex flex-col space-y-4'>
            <button
              onClick={handleAddToCart}
              className='w-full bg-indigo-600 text-white py-3 rounded-md hover:bg-indigo-700 transition duration-200 flex items-center justify-center'
              style={{ backgroundColor: '#004d61' }}
            >
              <FaShoppingCart className='mr-2' /> Add to Cart
            </button>
            {/* <button
              style={{ color: '#004d61', bordercolor: '#004d61' }}
              className='w-full bg-white border border-indigo-600 text-indigo-600 py-3 rounded-md hover:bg-indigo-50 transition duration-200'
            >
              Build Your Bundle & Save!
            </button> */}
          </div>

          {/* Additional Information */}
          <Accordian />
        </div>
      </div>
    </div>
  );
};

export default Product;
