import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAdminAuth } from '../contexts/AdminAuthContext';
import AdminLogin from '../Pages/Admin/login/AdminLogin';
import Dashboard from '../Pages/Admin/Dashboard/Dashboard';
import AddProduct from '../Pages/Admin/Products/AddProduct/AddProduct';
import Products from '../Pages/Admin/Products/Products';
import ProductDetail from '../Pages/Admin/Products/ProductDetails/ProductDetaill';
import Sidebar from '../Pages/Admin/Sidebar/Sidebar';
import Topbar from '../Pages/Admin/Topbar/Topbar';
import Chat from '../Pages/Admin/ChatPage/Chat';
import AdminOrders from 'Pages/Admin/Orders/AdminOrders/AdminOrder';
import OrderDetails from 'Pages/Admin/Orders/OrderDetails/OrderDetails';
import EditOrder from 'Pages/Admin/Orders/EditOrder/EditOrder';
import ContactUsAdmin from 'Pages/Admin/ContactUs/ContactUs';

// Component to protect admin routes
const AdminRoute = ({ children }) => {
  const { admin } = useAdminAuth();
  console.log('Admin state:', admin); // Check if the admin state is being set correctly
  return admin ? children : <Navigate to='/admin/login' />;
};

const AdminRoutes = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  console.log('Sidebar State:', isSidebarOpen); // Log to ensure state is updating

  return (
    <div className='admin-wrapper'>
      <Routes>
        <Route path='/login' element={<AdminLogin />} />
        <Route
          path='/*'
          element={
            <AdminRoute>
              <Sidebar isOpen={isSidebarOpen} />
              <div
                className={`dashboardContent ${isSidebarOpen ? '' : 'closed'}`}
              >
                <Topbar
                  toggleSidebar={toggleSidebar}
                  isSidebarOpen={isSidebarOpen}
                />
                <div className='mainContent'>
                  <Routes>
                    <Route path='secret-dashboard' element={<Dashboard />} />
                    <Route
                      path='secret-dashboard/products'
                      element={<Products />}
                    />
                    <Route
                      path='secret-dashboard/products/add'
                      element={<AddProduct />}
                    />
                    <Route
                      path='secret-dashboard/products/:id'
                      element={<ProductDetail />}
                    />
                    <Route
                      path='secret-dashboard/live-chat'
                      element={<Chat />}
                    />
                    <Route
                      path='secret-dashboard/orders'
                      element={<AdminOrders />}
                    />
                    <Route
                      path='secret-dashboard/orders/:orderId'
                      element={<OrderDetails />}
                    />
                    <Route
                      path='secret-dashboard/orders/edit/:orderId'
                      element={<EditOrder />}
                    />
                    <Route
                      path='secret-dashboard/contactus'
                      element={<ContactUsAdmin />}
                    />
                  </Routes>
                </div>
              </div>
            </AdminRoute>
          }
        />

        {/* Redirect any unknown routes */}
        <Route path='*' element={<Navigate to='/admin/login' />} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;
