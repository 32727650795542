import axios from 'axios';

const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_MEGA_SERVER,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true, // Allows cookies to be sent with requests
});

// Response interceptor to handle errors
AxiosClient.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if ([419, 403].includes(error.response?.status)) {
      // Optionally handle session timeout or forbidden access cases
    }
    return Promise.reject(error);
  },
);

export default AxiosClient;
