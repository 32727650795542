import React, { useEffect } from 'react';
import { useCart } from '../../../contexts/CartContext';
import './Cart.css';

const CartPage = () => {
  const { cart, fetchCart, updateOrCreateCart } = useCart();
  const [products, setProducts] = React.useState([]);

  // Fetch cart on page load
  useEffect(() => {
    fetchCart(); // Fetch the current cart
  }, [fetchCart]);

  // Fetch products from backend
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('http://localhost:5000/api/products'); // Fetch available products
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  // Handle adding or updating a product in the cart
  const handleAddProduct = (productId) => {
    updateOrCreateCart(productId, 1); // Add product with quantity 1 to cart
  };

  return (
    <div className='cart-page'>
      {/* Products Section */}
      <div className='products-section'>
        <h1 className='section-title'>Available Products</h1>
        <ul className='product-list'>
          {products.map((product) => (
            <li key={product.id} className='product-item'>
              <span>
                {product.name} - AED {product.price}
              </span>
              <button
                className='add-btn'
                onClick={() => handleAddProduct(product.id)}
              >
                +
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Cart Section */}
      <div className='cart-section'>
        <h1 className='section-title'>Your Current Cart</h1>
        {cart.length > 0 ? (
          <ul className='cart-items'>
            {cart.map((item) => (
              <li key={item.productId} className='cart-item'>
                <span>
                  {item.product.name} - Quantity: {item.quantity}
                </span>
                <button
                  className='remove-btn'
                  onClick={() => updateOrCreateCart(item.productId, -1)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className='empty-cart-message'>Your cart is empty</p>
        )}
      </div>
    </div>
  );
};

export default CartPage;
