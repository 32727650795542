import React from 'react';
import './PrivacyPolicy.css'; // Assuming you will create a CSS file for styles

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1  data-aos-once="true" data-aos="zoom-in" className="privacy-title">Privacy Policy – 6Lytes</h1>
      <p  data-aos-once="true" data-aos="fade-right" className="privacy-intro">
        At 6Lytes, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect, how we use it, and your rights regarding your personal data.
      </p>
      
      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="information-collection">
        <h2>Information We Collect</h2>
        <p>
          We collect personal information when you interact with our website, make a purchase, or contact us. The types of information we collect include:
        </p>
        <ul>
          <li>Personal Identifiable Information (PII): Such as your name, email address, phone number, and billing/shipping address.</li>
          <li>Payment Information: Including your credit card details or other payment methods, processed securely through third-party payment processors.</li>
          <li>Usage Data: Information about how you use our website, such as IP addresses, browser type, operating system, and browsing patterns.</li>
        </ul>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="how-we-use-information">
        <h2>How We Use Your Information</h2>
        <p>
          We use your personal data for various purposes, including:
        </p>
        <ul>
          <li>Order Processing: To fulfill your orders, process payments, and deliver products.</li>
          <li>Communication: To send you order updates, respond to inquiries, or inform you of promotions and new products (with your consent).</li>
          <li>Improvement of Services: To analyze website usage and improve our products, services, and website functionality.</li>
          <li>Marketing: With your consent, we may send promotional emails or newsletters. You can opt-out of these communications at any time.</li>
        </ul>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="data-sharing">
        <h2>Data Sharing</h2>
        <p>
          We do not sell, trade, or rent your personal information to third parties. However, we may share your data with:
        </p>
        <ul>
          <li>Service Providers: Such as payment processors, shipping companies, and email service providers, who help us run our business smoothly.</li>
          <li>Legal Obligations: If required by law, we may disclose your information to comply with legal processes, governmental requests, or court orders.</li>
        </ul>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="data-security">
        <h2>Data Security</h2>
        <p>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, please be aware that no method of online transmission is 100% secure, and we cannot guarantee absolute security.
        </p>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="cookies">
        <h2>Cookies</h2>
        <p>
          Our website uses cookies to enhance your browsing experience. Cookies are small files stored on your device that help us analyze web traffic, remember your preferences, and improve site functionality. You can choose to accept or decline cookies through your browser settings.
        </p>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="your-rights">
        <h2>Your Rights</h2>
        <p>
          You have the following rights regarding your personal data:
        </p>
        <ul>
          <li>Access: You can request access to the personal information we hold about you.</li>
          <li>Correction: You can request to correct any inaccuracies in your personal data.</li>
          <li>Deletion: You can request that we delete your personal information, subject to legal and contractual obligations.</li>
          <li>Opt-out of Marketing: You can opt-out of receiving marketing communications at any time by following the unsubscribe instructions in our emails.</li>
        </ul>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="third-party-links">
        <h2>Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of those sites. We recommend reviewing their privacy policies before providing any personal information.
        </p>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="changes-to-policy">
        <h2>Changes to Our Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. Any updates will be posted on this page, and the revised policy will be effective immediately upon posting.
        </p>
      </div>

      <div  data-aos-once="true" data-aos="fade-right" className="privacy-section" id="contact-us">
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our Privacy Policy or how we handle your personal data, please contact us at: <strong>Email: support@6lytes.com</strong>
        </p>
      </div>

      <p  data-aos-once="true" data-aos="fade-right">
        Your privacy is important to us, and we are committed to protecting your personal information with care and respect. Thank you for trusting 6Lytes!
      </p>
    </div>
  );
};

export default PrivacyPolicy;
