import React from 'react';
import styles from './BoxInfo.module.css';
import {
  AiOutlineCalendar,
  AiOutlineTeam,
  AiOutlineDollarCircle,
} from 'react-icons/ai';

const BoxInfo = () => {
  return (
    <div className={styles.boxInfo}>
      <div className={styles.box}>
        <AiOutlineCalendar className={styles.icon} />
        <div className={styles.text}>
          <h3>1020</h3>
          <p>New Orders</p>
        </div>
      </div>
      <div className={styles.box}>
        <AiOutlineTeam className={styles.icon} />
        <div className={styles.text}>
          <h3>2834</h3>
          <p>Visitors</p>
        </div>
      </div>
      <div className={styles.box}>
        <AiOutlineDollarCircle className={styles.icon} />
        <div className={styles.text}>
          <h3>$2543</h3>
          <p>Total Sales</p>
        </div>
      </div>
    </div>
  );
};

export default BoxInfo;
