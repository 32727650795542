import React from 'react';
import banner3 from '../../../assets/3.jpg';
import banner3Mobile from '../../../assets/3 B.jpg'; 
import './Section13.css';

const Section13 = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <div className='banner-container13'>
      <img 
        className='banner13' 
        data-aos="fade-up" 
        src={isMobile ? banner3Mobile : banner3} 
        alt='banner' 
      />
    </div>
  );
}

export default Section13;
