import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import './Section3.css';
import apple from '../../../assets/6LYETS_RENDER_APPLE.png';
import lime from '../../../assets/6LYETS_RENDER_LIME.png';
import orange from '../../../assets/6LYETS_RENDER_ORANGE.png';
import watermelon from '../../../assets/6LYETS_RENDER_WATERMELON.png';
import CartSlider from '../../../components/CartSlider';
import { useCart } from '../../../contexts/CartContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AxiosClient from 'utilities/axiosClient';
dayjs.extend(utc);
dayjs.extend(timezone);
const fallbackProducts = [
  { id: 1, img: apple, title: 'Apple', price: 75.0 },
  { id: 2, img: lime, title: 'Lime', price: 75.0 },
  { id: 3, img: watermelon, title: 'Watermelon', price: 75.0 },
  { id: 4, img: orange, title: 'Orange', price: 75.0 },
];

const Section3 = () => {
  const [products, setProducts] = useState([]);
  const { cart, isCartOpen, toggleCart, updateOrCreateCart } = useCart();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const userTimezone = dayjs.tz.guess();

        const response = await AxiosClient.get('/products', {
          params: {
            timezone: userTimezone,
          },
        });
        console.log(response.data,"response.dataa====  ")
        setProducts(response.data);
      } catch (error) {
        setProducts();
        console.error('Error fetching products:', error);
      }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = (product) => {
    // Find if the product is already in the cart
    const existingCartItem = cart.find((item) => item.productId === product.id);

    // If item exists, increase quantity by 1, else add it with quantity 1
    const newQuantity = existingCartItem ? existingCartItem.quantity + 1 : 1;

    updateOrCreateCart(product.id, newQuantity);

    // Open cart slider only if it's currently closed
    if (!isCartOpen) {
      toggleCart();
    }
  };

  const formatPrice = (price) => {
    const numPrice = parseFloat(price);
    return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2);
  };

  return (
    <div className='top-products3'>
      <div className='container3'>
        <div className='header-section3'>
          <h1 data-aos='fade-down' className='heading3'>
            Our Products
          </h1>
        </div>
        <div className='products-grid3'>
          {products?.map((product) => (
            <div key={product.id} className='product-card3' data-aos='zoom-in'>
              <div className='image-section3'>
                <img
                  src={product.img || product.productImages[0]?.imageUrl}
                  alt={product.title}
                  className='product-image3'
                />
              </div>
              <div data-aos='fade-down' className='details-section3'>
                <h1 className='product-title3'>{product.title}</h1>
                <p className='product-price3'>
                  {product.currency} {formatPrice(product.price)}
                </p>
                <div className='star-rating3'>
                  {[...Array(4)].map((_, i) => (
                    <FaStar key={i} className='star3' />
                  ))}
                </div>
                <button
                  className='order-button3'
                  onClick={() => handleAddToCart(product)}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          ))}
        </div>
        <CartSlider />
      </div>
    </div>
  );
};

export default Section3;