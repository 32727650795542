import React from 'react';
import styles from './Table.module.css';

const Table = () => {
  return (
    <div className={styles.table}>
      <h3 className={styles.title}>Recent Orders</h3>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Date Order</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>John Doe</td>
            <td>01-10-2023</td>
            <td>
              <span className={`${styles.status} ${styles.completed}`}>
                Completed
              </span>
            </td>
          </tr>
          <tr>
            <td>Jane Smith</td>
            <td>02-10-2023</td>
            <td>
              <span className={`${styles.status} ${styles.pending}`}>
                Pending
              </span>
            </td>
          </tr>
          <tr>
            <td>Alex Johnson</td>
            <td>03-10-2023</td>
            <td>
              <span className={`${styles.status} ${styles.process}`}>
                In Process
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
