import Section1 from "./Section 1/Section1";
import Section3 from "./Section 3/Section3";

import "./LandingPage.css"
import Section5 from "./Section 5/Section5";
import Section4 from "./Section 4/Section4";
import Section7 from "./Section 7/section7";
import Section8 from "./Section 8/Section8";
import Section10 from "./Section 10/Section10";
import Section13 from "./Section 13/Section13";
import Section2 from "./Section 2/Section2";
import Section6 from "./Section 6/Section6";
import Section14 from "./Section 14/Section14";
import Section15 from "./Section 15/Section15";

const LandingPage = () => {
  return (
    <main className="landing-page-container">
      <Section1 />
      <Section2/>
      <Section3 /> 
      <Section4 />
      <Section8/>
      <Section13/>
      <Section7/>
      <Section5 />
      <Section10/>
      <Section6/>
      <Section15/>
    </main>
  );
};

export default LandingPage;
