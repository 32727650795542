import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './OrderDetails.css';
import AxiosClient from 'utilities/axiosClient';
import { useAdminAuth } from 'contexts/AdminAuthContext';

const OrderDetails = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const { admin } = useAdminAuth();

  // Fetch the order details
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await AxiosClient.get(`/orders/${orderId}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${admin.token}`,
          },
        });
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [orderId]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className='order-details'>
      <h1>Order Details - ID {order.id}</h1>
      <p>
        <strong>User:</strong> {order.user?.email || 'Guest'}
      </p>
      <p>
        <strong>Total Amount:</strong> {order.totalAmount}
      </p>
      <p>
        <strong>Status:</strong> {order.status}
      </p>
      <p>
        <strong>Tracking ID:</strong> {order.trackingId || 'N/A'}
      </p>

      <h2>Order Items</h2>
      <ul>
        {order.orderItems.map((item) => (
          <li key={item.id}>
            {item.product.name} - {item.quantity} x {item.price}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderDetails;
