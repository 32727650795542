import React from "react";
import './Section5.css'; 

const Section5 = () => {
  return (
    <div
      data-aos="zoom-in"
      className="section5-container"
    >
      <div className="backdrop-blur-sm py-14">
        <div className="space-y-6 max-w-xl mx-4">
          <h1  data-aos="fade-down" className="section5-title">
            Get Notified About New Products
          </h1>
          <input
            data-aos="fade-up"
            type="text"
            placeholder="Enter your email"
            className="section5-input"
          />
        </div>
      </div>
    </div>
  );
};

export default Section5;
