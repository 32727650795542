import React, { useState } from 'react';
import { MobileStepper, Button, useMediaQuery } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import './Section15.css';

import image from '../../../assets/6LYETS_RENDER_APPLE.png'

const testimonials = [
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image, 
  },
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image,
  },
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image,
  },
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image,
  },
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image,
  },
  {
    review: "Lorem ipsum dolor sit amet, consectetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    name: 'Nikhil Anantraman',
    product: 'HomeLone',
    image: image,
  },
];

const Section15 = () => {
  const [activeStep, setActiveStep] = useState(0);
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:1024px)');
  
  const itemsPerSlide = isDesktop ? 3 : isTablet ? 2 : 1;
  const maxSteps = Math.ceil(testimonials.length / itemsPerSlide); // Adjust the number of steps

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep < maxSteps - 1) {
        return prevActiveStep + 1; 
      }
      return prevActiveStep; 
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 0) {
        return prevActiveStep - 1; 
      }
      return prevActiveStep; 
    });
  };

  const currentItems = testimonials.slice(activeStep * itemsPerSlide, (activeStep + 1) * itemsPerSlide);

  return (
    <div className="testimonial-section">
      <div className="testimonial-container" style={{ display: 'flex', gap: '20px', overflow: 'hidden' }}>
        {currentItems.map((testimonial, index) => (
          <div className="testimonial-card" key={index} style={{ flex: '1 1 auto' }}>
             <img src={testimonial.image} alt={testimonial.name} className="section15-profile-image" />
            <p className="quote">“{testimonial.review}”</p>
            <div className="section15-profile">
              <div className="profile-info">
                <p className="name">{testimonial.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <MobileStepper
        variant="dots"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            <KeyboardArrowRight sx={{ color: 'white' }} />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft sx={{ color: 'white' }} />
          </Button>
        }
        sx={{
          background: "linear-gradient(to right, #0000ff, #ff00ff)",
          color: "white",
          marginTop: '20px',
          borderRadius: '9999px',
          '.MuiMobileStepper-dotActive': {
            backgroundColor: 'white',
          },
        }}
      />
    </div>
  );
};

export default Section15;
