import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ProductHeader = () => {
  return (
    <div className='flex flex-col md:flex-row justify-between items-center bg-gray-50 px-3 py-2 rounded-lg shadow-sm'>
      {/* Breadcrumb Navigation */}
      <div className='mb-3 md:mb-0'>
        <nav className='text-xs md:text-sm'>
          <ul className='flex space-x-1 text-gray-500'>
            <li>
              <a
                href='/'
                className='hover:underline hover:text-indigo-500 transition-colors duration-150'
              >
                Shop
              </a>
            </li>
            <li>{'>'}</li>
            <li>
              <a
                href='/collections/hydrate-sticks'
                className='hover:underline hover:text-indigo-500 transition-colors duration-150'
              >
                Products
              </a>
            </li>
            <li>{'>'}</li>
            <li className='text-gray-700 font-medium'>Hydrate Lime Burst</li>
          </ul>
        </nav>
      </div>

      {/* <div className='flex gap-2'>
        <button
          style={{ backgroundColor: '#004d61' }}
          className='flex items-center px-3 py-1  text-white text-sm rounded-full shadow hover:bg-indigo-700 transition duration-150 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400'
        >
          <FaArrowLeft />
          <span className='ml-1'>Previous</span>
        </button>

        <button
          style={{ backgroundColor: '#004d61' }}
          className='flex items-center px-3 py-1 bg-indigo-600 text-white text-sm rounded-full shadow hover:bg-indigo-700 transition duration-150 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-400'
        >
          <span className='mr-1'>Next</span>
          <FaArrowRight />
        </button>
      </div> */}
    </div>
  );
};

export default ProductHeader;
