import React, { useState } from 'react';
import axios from 'axios';
import './AddProduct.css';
import { useAdminAuth } from '../../../../contexts/AdminAuthContext';
import AxiosClient from 'utilities/axiosClient';

const AddProduct = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [benefits, setBenefits] = useState('');
  const [howToUse, setHowToUse] = useState('');
  const [nutritionInfo, setNutritionInfo] = useState('');
  const [stock, setStock] = useState('');
  const [files, setFiles] = useState([]);
  const [pricePKR, setPricePKR] = useState('');
  const [priceAED, setPriceAED] = useState('');
  const { admin } = useAdminAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('benefits', benefits);
    formData.append('howToUse', howToUse);
    formData.append('nutritionInfo', nutritionInfo);
    formData.append('stock', stock);
    formData.append(
      'prices',
      JSON.stringify([
        { currency: 'PKR', price: pricePKR },
        { currency: 'AED', price: priceAED },
      ]),
    );
    files.forEach((file) => formData.append('files', file));

    try {
      await AxiosClient.post('/products/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${admin.token}`,
        },
      });
      alert('Product added successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product');
    }
  };

  return (
    <div className='add-product'>
      <h2>Add New Product</h2>
      <form onSubmit={handleSubmit} className='add-product-form'>
        <input
          type='text'
          placeholder='Product Name'
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <input
          type='number'
          placeholder='Price (PKR)'
          value={pricePKR}
          onChange={(e) => setPricePKR(e.target.value)}
          required
        />
        <input
          type='number'
          placeholder='Price (AED)'
          value={priceAED}
          onChange={(e) => setPriceAED(e.target.value)}
          required
        />
        <textarea
          placeholder='Description'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <textarea
          placeholder='Benefits'
          value={benefits}
          onChange={(e) => setBenefits(e.target.value)}
        />
        <textarea
          placeholder='How to Use'
          value={howToUse}
          onChange={(e) => setHowToUse(e.target.value)}
        />
        <textarea
          placeholder='Nutrition Info'
          value={nutritionInfo}
          onChange={(e) => setNutritionInfo(e.target.value)}
        />
        <input
          type='number'
          placeholder='Stock Quantity'
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
        />
        <input
          type='file'
          multiple
          onChange={(e) => setFiles(Array.from(e.target.files))}
          required
        />
        <button type='submit'>Add Product</button>
      </form>
    </div>
  );
};

export default AddProduct;
