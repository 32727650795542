import React, { useState, useEffect, useRef } from 'react';
import { useChat } from '../../../../contexts/ChatContext';
import { FaUserCircle, FaShieldAlt } from 'react-icons/fa';
import './ChatRoom.css';

const ChatRoom = ({ chatRoomId }) => {
  const [inputMessage, setInputMessage] = useState('');
  const { sendMessage, getChat, socket } = useChat();
  const [allMessages, setAllMessages] = useState([]);
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const chatBodyRef = useRef(null);

  useEffect(() => {
    const fetchChat = async () => {
      const response = await getChat(chatRoomId);
      const { messages } = response;
      setAllMessages(messages);
      socket.emit('joinRoom', { chatRoomId });
    };
    fetchChat();

    // Listen for new messages and typing events
    socket.on('newMessage', (message) => {
      setAllMessages((prevMessages) => [...prevMessages, message]);
    });

    socket.on('typing', (data) => {
      setTyping(data.isTyping);
    });

    return () => {
      socket.off('newMessage');
      socket.off('typing');
    };
  }, [chatRoomId]);

  // Scroll to the bottom whenever new messages are added
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom on initial render and when messages change
  }, [allMessages]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      const messageData = {
        text: inputMessage,
        senderType: 'ADMIN',
      };
      sendMessage(inputMessage, 'ADMIN', chatRoomId);
      socket.emit('newMessage', messageData);
      setAllMessages((prev) => [...prev, messageData]);
      setInputMessage('');
    }
  };

  const handleKeyDown = () => {
    if (!typing) {
      socket.emit('typing', { chatRoomId, isTyping: true });
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
  };

  const handleKeyUp = () => {
    const timeout = setTimeout(() => {
      socket.emit('typing', { chatRoomId, isTyping: false });
    }, 500);
    setTypingTimeout(timeout);
  };

  return (
    <div className='chat-room'>
      <div className='messages' ref={chatBodyRef}>
        {allMessages?.map((msg, idx) => (
          <div
            key={idx}
            className={`message-bubble ${
              msg.senderType === 'ADMIN' ? 'admin' : 'user'
            }`}
          >
            <div className='message-content'>
              {msg.senderType === 'ADMIN' ? (
                <FaShieldAlt className='message-icon admin-icon' />
              ) : (
                <FaUserCircle className='message-icon user-icon' />
              )}
              <p className='message-text'>{msg.text}</p>
            </div>
          </div>
        ))}
      </div>
      {typing && <p className='typing-indicator'>User is typing...</p>}
      <div className='input-area'>
        <textarea
          placeholder='Type a message'
          className='chatRoom-textarea'
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
        <button onClick={handleSendMessage} className='chatRoom-button'>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatRoom;
