import React from "react";
import "./TermsConditions.css";

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h1  data-aos-once="true" data-aos="zoom-in" className="terms-title">Terms and Conditions</h1>
      
      <p  data-aos-once="true" data-aos="fade-right" className="terms-intro">
        Welcome to 6Lytes. By using our website and purchasing our products, you agree to comply with and be bound by the following terms and conditions. Please read them carefully before using our services.
      </p>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>General Information</h2>
        <p>These Terms and Conditions apply to all users of this website and customers of 6Lytes. By accessing our website or purchasing from us, you agree to these terms. If you do not agree with these terms, please do not use our services.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Products and Services</h2>
        <p>All descriptions of products and their prices are subject to change at any time without notice. We strive to ensure that all product descriptions and images are as accurate as possible; however, we cannot guarantee that all product information is entirely free of errors.</p>
        <ul>
          <li>Limit the sales of our products to any person, geographic region, or jurisdiction.</li>
          <li>Discontinue any product at any time.</li>
          <li>Limit the quantities of any products or services that we offer.</li>
        </ul>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Orders and Payments</h2>
        <p>By placing an order on our website, you agree to provide current, complete, and accurate purchase information. We reserve the right to refuse or cancel your order if any fraudulent or unauthorized activity is suspected.</p>
        <p>We accept payments through various secure methods. Please ensure that your payment information is valid and authorized before completing a purchase.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Shipping and Delivery</h2>
        <p>We aim to process and ship orders as quickly as possible. However, delivery times are estimates and subject to changes due to external factors beyond our control (such as courier delays or unforeseen events).</p>
        <p>6Lytes is not responsible for shipping delays once the product has been handed over to the courier service.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Limitation of Liability</h2>
        <p>In no case shall 6Lytes, our directors, employees, or affiliates, be liable for any direct, indirect, incidental, punitive, or consequential damages arising from your use of this website or purchase of our products. This includes, but is not limited to, errors, omissions, or inaccuracies in product descriptions, delays in product delivery, or any other service-related issues.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Intellectual Property</h2>
        <p>All content on this website, including but not limited to logos, text, graphics, images, and product descriptions, is the property of 6Lytes. You may not reproduce, duplicate, or resell any part of this website or its contents without prior written permission from us.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Governing Law</h2>
        <p>These Terms and Conditions, and any separate agreements whereby we provide you services, shall be governed by and construed in accordance with the laws of Pakistan.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Changes to Terms and Conditions</h2>
        <p>We reserve the right to modify or update these terms at any time. Any changes will be posted on this page, and your continued use of our website constitutes acceptance of those changes.</p>
      </section>
      
      <section   data-aos-once="true" data-aos="fade-right" className="terms-section">
        <h2>Contact Information</h2>
        <p>If you have any questions regarding these terms, please contact us at <strong>Email: support@6lytes.com</strong></p>
      </section>
    </div>
  );
};

export default TermsConditions;
